/* eslint-disable prettier/prettier */
import { faXmark, faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    setMapViewPortCenterCoords,
    setPropertyCardClassName,
    setPropertyCardItem
} from "../../store";
import favoritePropertySliceState, {
    setFavoritesPopup
} from "../../store/favoriteProperty";
import PartButton from "../parts/PartButton";
import FavoritePropertyService from "../../domain/favoriteProperty/FavoritePropertyService";
import Collection from "../../domain/valueObject/Collection";
import mapTriggersSliceState, {
    setHidePropertyCard,
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered,
    setShowFavoritePopup,
    setTriggerPropretyServiceProviders
} from "../../store/mapTriggers";
import TypeString from "../../domain/valueObject/TypeString";
import PropertyService from "../../domain/property/PropertyService";
import { hotjar } from "react-hotjar";
import { favoriteOptions } from "../../utils/options/favoriteOptions";
import { useSearchParams } from "react-router-dom";
import BlockServiceProviders from "./BlockServiceProviders";
import PartFavoritePopupSkeleton from "../parts/PartFavoritePopupSkeleton";

const BlockFavoritesPopup = (propertyId:any) => {
    const dispatch = useDispatch();
    const [vendorRolesDropdownValues, setVendorRolesDropdownValues] =
        useState<any>([]);
    const [starReasonDropdownValues, setStarReasonDropdownValues] =
        useState<any>([]);
    const favoriteSlice = useSelector(favoritePropertySliceState);
    const hasCurrentData = favoriteSlice.favoritesPopup.id === propertyId.propertyId;
    const mapTriggersSlice = useSelector(mapTriggersSliceState);
    const query = PropertyService.getSearchQuery();
    const [searchParams, setSearchParams] = useSearchParams();

    const defaultCheckboxValue: string[] = [];
    const checkedValuesRef = useRef(defaultCheckboxValue);

    const { setValue, watch } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        defaultValues: {
            favorite: false
        }
    });

    const starReasonOnChange = async (checkedValues: CheckboxValueType[]) => {
        setStarReasonDropdownValues(checkedValues);
        updatePropertyFavorite(checkedValues, vendorRolesDropdownValues);

        if (checkedValues.length > checkedValuesRef.current.length) {
            const addedItem = checkedValues.filter(
                (currentItem) =>
                    !checkedValuesRef.current.includes(currentItem as any)
            );
            const selectedOption = favoriteOptions.find((option) =>
                addedItem.includes(option.value)
            );
            hotjar.event(
                `Favorite Popup - Relationship Type: ${selectedOption?.label} Checked - ${favoriteSlice.favoritesPopup.id}`
            );
        } else {
            const removedItem = checkedValuesRef.current.filter(
                (currentItem) => !checkedValues.includes(currentItem as any)
            );
            const deselectedOption = favoriteOptions.find((option) =>
                removedItem.includes(option.value)
            );
            if (deselectedOption) {
                hotjar.event(
                    `Favorite Popup - Relationship Type: ${deselectedOption.label} Unchecked - ${favoriteSlice.favoritesPopup.id}`
                );
            }
        }

        checkedValuesRef.current = checkedValues as any;
    };

    const updatePropertyFavorite = async (
        starReasons?: any,
        vendorRolesValues?: any
    ) => {
        try {
            const data = new Collection({});
            data.set("propertyID", favoriteSlice.favoritesPopup.id);
            if (vendorRolesValues && vendorRolesValues.length > 0) {
                const vendorRoles = vendorRolesValues.map((item: any) => {
                    return item.key;
                });
                data.set("vendorRoles", vendorRoles);
            }
            if (starReasons.length > 0) {
                data.set("starReasons", starReasons);
                dispatch(setPropertyFavoriteActionTriggered(true));
                await FavoritePropertyService.addToFavorite(data);
            } else {
                dispatch(setPropertyFavoriteActionTriggered(true));
                removePropertyFromFavorites();
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setTriggerPropretyServiceProviders(false));
            dispatch(setPropertyFavoriteActionTriggered(false));
        }
    };

    useEffect(() => {
        getPropertyStar();
        setValue("favorite", true);

        return () => {
            // check if property was not set to favorite and set it to favorite
            // if (indexSlice.propertyCardItem.data &&
            //     indexSlice.propertyCardItem.data.isFavorite.value === false) {
            //     addPropertyToFavorites();
            // }
            setVendorRolesDropdownValues([]);
            setStarReasonDropdownValues([]);
            setValue("favorite", false);
        };
    }, [ favoriteSlice.userPropertyStar]);

    const removePropertyFromFavorites = async () => {
        try {
            const propertyId = new TypeString(favoriteSlice.favoritesPopup.id);
            setStarReasonDropdownValues([]);
            setVendorRolesDropdownValues([]);
            await FavoritePropertyService.removeFromFavorite(propertyId);
            dispatch(setPropertyFavoriteActionTriggered(true));
            dispatch(setPropertyCardMarkAsFavorite(false));
            query.apply();
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setPropertyFavoriteActionTriggered(false));
        }
    };

    const getPropertyStar = () => {
        if (
            favoriteSlice.userPropertyStar &&
            favoriteSlice.userPropertyStar.starReasons.length
        ) {
            setStarReasonDropdownValues(
                favoriteSlice.userPropertyStar.starReasons
            );
        } else if (
            //Needed for backwards compatibility - store starReason as Other if starType is Other
            favoriteSlice.userPropertyStar?.starType &&
            favoriteSlice.userPropertyStar.starType === "OTHER"
        ) {
            setStarReasonDropdownValues(["OTHER"]);
        }

        if (
            favoriteSlice.userPropertyStar?.vendorRoles &&
            favoriteSlice.userPropertyStar.vendorRoles.length > 0
        ) {
            const vendorRoles = favoriteSlice.userPropertyStar.vendorRoles;
            const filteredRoles = favoriteSlice.vendorRoles
                .filter((item: any) => {
                    return vendorRoles.includes(item.value);
                })
                .map((item: any) => {
                    return { key: item.value, label: item.label };
                });
            setVendorRolesDropdownValues(filteredRoles);
        }
    };

    const favoritePropertyCheckbox = watch("favorite");

    const closePropertyCard = () => {
        if (mapTriggersSlice.hidePropertyCard) {
            dispatch(setHidePropertyCard(false));
            dispatch(
                setPropertyCardItem({
                    data: null,
                    isVisible: false
                })
            );
            dispatch(setMapViewPortCenterCoords(undefined));
            dispatch(setPropertyCardClassName(""));

            searchParams.delete("propertyId");
            setSearchParams(searchParams);
        }
    };
    return hasCurrentData ? (
        <>
            <div
                className="overlay"
                onClick={() => {
                    closePropertyCard();
                    dispatch(setFavoritesPopup(null));
                    dispatch(setShowFavoritePopup(false));
                }}
            ></div>
            <div className="popup popup-favorites">
                {/* <h3>Favorite</h3> */}
                <span
                    className="popup__close"
                    onClick={() => {
                        hotjar.event(
                            `Favorite Popup - Close Button Click - ${favoriteSlice.favoritesPopup.id}`
                        );
                        closePropertyCard();
                        dispatch(setFavoritesPopup(null));
                        dispatch(setShowFavoritePopup(false));
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </span>
                <div className="popup-scrollable">
                    <div className="popup-favorites__header">
                        <h3>Pipeline Tracking Tags</h3>
                        <div className="popup-favorites__header__address">
                            <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                            ></FontAwesomeIcon>
                            <span className="popup-favorites__header__address-text">
                                {favoriteSlice.favoritesPopup.address}
                            </span>
                        </div>
                    </div>
                    <p>
                        Selecting additional tags will{" "}
                        <b>help you track your involvement</b> with this
                        property. This information is private and only visible
                        to you.
                    </p>
                    <Checkbox.Group
                        className="popup-favorites__group"
                        options={favoriteOptions}
                        value={starReasonDropdownValues}
                        disabled={!favoritePropertyCheckbox}
                        defaultValue={defaultCheckboxValue}
                        onChange={starReasonOnChange}
                    />
                    <hr />
                    <BlockServiceProviders />
                </div>
                <div className="popup-favorites__footer">
                    <PartButton
                        type="primary"
                        onClick={() => {
                            hotjar.event(
                                `Favorite Popup - Done Button Click - ${favoriteSlice.favoritesPopup.id}`
                            );
                            dispatch(setFavoritesPopup(null));
                            dispatch(setShowFavoritePopup(false));
                        }}
                    >
                        Done
                    </PartButton>
                </div>
            </div>
        </>
    ) : null;
};

export default BlockFavoritesPopup;
