import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { hotjar } from "react-hotjar";
import { useDispatch } from "react-redux";
import { setToken } from "../store";
import { calculateDaysLeft, isTrialPeriodExpired } from "../utils/utils";

const isPaidUserHook = async () => {
    const [isPaidUser, setIsPaidUser] = useState<boolean>(false);
    const [isUnpaidUser, setIsUnpaidUser] = useState<boolean>(false);
    const [isLenderUser, setIsLenderUser] = useState<boolean>(false);
    const [isLimitedUser, setIsLimitedUser] = useState<boolean>(false);
    const [subscriptionExpired, setSubscriptionExpired] = useState(false);
    const [daysLeft, setDaysLeft] = useState(0);
    const { getAccessTokenSilently, user } = useAuth0();
    const dispatch = useDispatch();

    const getIsUserLoggedIn = () => {
        return !!user;
    };

    const sendUserDataToHotjar = async (decodedToken: any) => {
        const lenderPaid: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isLenderPaid`,
            gpPaid: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isGpPaid`,
            email: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/email`,
            loanTrackerId: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/loanTrackerId`,
            sub: any = "sub",
            subscriptionExpiry: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/subscription_expiry`;

        hotjar.identify(decodedToken[sub], {
            email: decodedToken[email],
            subscription_expiry: String(decodedToken[subscriptionExpiry]),
            loanTrackerId: decodedToken[loanTrackerId],
            lenderPaid: decodedToken[lenderPaid],
            gpPaid: decodedToken[gpPaid]
        });
    };

    const getIsPaidUser = async () => {
        const paidUserTokenKey: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isPaidMapUser`;
        const auth0Token = await getAccessTokenSilently();
        dispatch(setToken(auth0Token));

        const decodedToken: any = jwt_decode(auth0Token);
        setIsPaidUser(decodedToken[paidUserTokenKey] ?? false);
        // setIsPaidUser(true);
    };

    const getIsTrialUser = async () => {
        const trialUserTokenKey: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isTrial`;
        const auth0Token = await getAccessTokenSilently();
        dispatch(setToken(auth0Token));

        const decodedToken: any = jwt_decode(auth0Token);
        setIsUnpaidUser(decodedToken[trialUserTokenKey] ?? false);
        // setIsUnpaidUser(false);
    };

    const getIsLenderUser = async () => {
        const lenderUserTokenKey: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isLender`;
        const auth0Token = await getAccessTokenSilently();
        dispatch(setToken(auth0Token));

        const decodedToken: any = jwt_decode(auth0Token);
        setIsLenderUser(decodedToken[lenderUserTokenKey] ?? false);
    };

    const getIsLimitedUser = async () => {
        const limitedUserTokenKey: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/isLimitedUser`;
        const auth0Token = await getAccessTokenSilently();
        dispatch(setToken(auth0Token));

        const decodedToken: any = jwt_decode(auth0Token);
        setIsLimitedUser(decodedToken[limitedUserTokenKey] ?? false);
    };

    const getIsSubscriptionExpired = async () => {
        const isTrialPeriodClaim: any = `${process.env.REACT_APP_AUTH0_CLAIM_DOMAIN}/subscription_expiry`;
        const auth0Token = await getAccessTokenSilently();
        const decodedToken: any = jwt_decode(auth0Token);
        const trialPeriod = decodedToken[isTrialPeriodClaim];
        // const trialPeriod: any = "12/25/2022"; // change here to test trial period
        sendUserDataToHotjar(decodedToken);

        if (trialPeriod) {
            const isExpired = isTrialPeriodExpired(new Date(trialPeriod));
            if (!isExpired) {
                setDaysLeft(calculateDaysLeft(new Date(trialPeriod)));
            } else {
                setSubscriptionExpired(true);
            }
        }
    };

    useEffect(() => {
        if (getIsUserLoggedIn()) {
            getIsPaidUser();
            getIsTrialUser();
            getIsLenderUser();
            getIsSubscriptionExpired();
            getIsLimitedUser();
        }
    }, [
        isPaidUser,
        subscriptionExpired,
        daysLeft,
        isUnpaidUser,
        isLenderUser,
        isLimitedUser,
        user
    ]);

    return {
        isPaidUser,
        subscriptionExpired,
        daysLeft,
        isUnpaidUser,
        isLenderUser,
        isLimitedUser
    };
};

export default isPaidUserHook;
