import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setAffilliatedPopup } from "../../store";
import PartButton from "../parts/PartButton";
import { useForm } from "react-hook-form";
import BlockServiceProviders from "./BlockServiceProviders";

const BlockAffiliatedPopup = () => {
    const dispatch = useDispatch();
    const {
        formState: { errors }
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        defaultValues: {
            favorite: false
        }
    });

    return (
        <>
            <div
                className="overlay"
                onClick={() => {
                    dispatch(setAffilliatedPopup(false));
                }}
            ></div>
            <div className="popup popup-favorites popup-favorites--affilliated">
                <span
                    className="popup__close"
                    onClick={() => {
                        dispatch(setAffilliatedPopup(false));
                    }}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </span>
                <BlockServiceProviders />
                <div className="popup-favorites__footer">
                    <PartButton
                        type="primary"
                        onClick={() => {
                            dispatch(setAffilliatedPopup(false));
                        }}
                    >
                        Done
                    </PartButton>
                </div>
            </div>
        </>
    );
};

export default BlockAffiliatedPopup;
