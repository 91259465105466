import { API_CLIENT_CONTENT_TYPE_FORM_DATA } from "./../../utils/http/ApiClient";
import axios, { AxiosRequestConfig } from "axios";
import { USER_DATA_API } from "../../apiList";
import APIClient, {
    API_CLIENT_CONTENT_TYPE_JSON
} from "../../utils/http/ApiClient";
import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";

export default class PropertyDocumentGateway {
    getAll(propertyId: TypeString) {
        return USER_DATA_API.get(`/property-documents/${propertyId.value}`);
    }

    deleteDocument(propertyID: TypeString, documentId: TypeString) {
        return USER_DATA_API.delete(
            `/property-documents/${propertyID}/${documentId}`
        );
    }

    uploadDocumentStep1(data: Collection) {
        return USER_DATA_API.post(`/property-documents`, data, {
            contentType: API_CLIENT_CONTENT_TYPE_JSON
        });
    }

    uploadDocumentStep2(
        url: string,
        data: any,
        contentType: string,
        handleOnUpload: any
    ) {
        const config = {
            method: "put",
            url,
            headers: {
                Accept: "*/*",
                "Content-Type": contentType
            },
            onUploadProgress: ({ loaded, total }: any) => {
                handleOnUpload(loaded, total);
            },
            data: data
        };

        return axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
