import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setIframe } from "../../../store";
import { WHERE_FROM_CTA } from "../../../utils/constants";
import BlockMembershipCTA from "../BlockMembershipCTA";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import PartPropertyCardSingleItem from "../../parts/PartPropertyCardSingleItem";
import { PropertyCardData } from "../../../domain/property/PropertyViewMapper";

interface BlockTabSaleHistoryProps {
    propertyCardData: PropertyCardData;
}

const BlockTabSaleHistory = ({
    propertyCardData
}: BlockTabSaleHistoryProps) => {
    const indexSlice = useSelector(indexSliceState);
    const dispatch = useDispatch();

    return (
        <div
            className={`property-card__tab property-card__tab--sale ${
                propertyCardData.saleHistory === undefined ? "short-tab" : ""
            }`}
        >
            {propertyCardData.saleHistory !== undefined ? (
                <>
                    <div className="property-card__public-group">
                        <h4>
                            SALE{" "}
                            <div className="property-card__public-hover left">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span>
                                    Data obtained through aggregation <br />
                                    of publicly available sources
                                </span>
                            </div>
                        </h4>
                        <PartPropertyCardSingleItem
                            name="Sale Date"
                            value={propertyCardData.saleHistory.saleDate}
                        />
                        <PartPropertyCardSingleItem
                            name="Sale Price"
                            value={
                                propertyCardData.saleHistory.price
                                    ? String(
                                          propertyCardData.saleHistory.price
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Price per Sq. Ft."
                            value={
                                propertyCardData.saleHistory.pricePerSqFt
                                    ? String(
                                          propertyCardData.saleHistory
                                              .pricePerSqFt
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Price per Unit"
                            value={
                                propertyCardData.saleHistory.pricePerUnit
                                    ? String(
                                          propertyCardData.saleHistory
                                              .pricePerUnit
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Prior Sale Date"
                            value={propertyCardData.saleHistory.priorSaleDate}
                        />
                        <PartPropertyCardSingleItem
                            name="Prior Sale Price"
                            value={
                                propertyCardData.saleHistory.priorSalePrice
                                    ? String(
                                          propertyCardData.saleHistory
                                              .priorSalePrice
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Selling Entity"
                            value={propertyCardData.saleHistory.sellerName}
                        />
                    </div>
                </>
            ) : (
                <span className="property-card__history-unavailable">
                    Public sales history data not available for this property.{" "}
                </span>
            )}
        </div>
    );
};

export default BlockTabSaleHistory;
