import React, { useEffect, useState } from "react";
import { Select, Checkbox } from "antd";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSelectPopupContainer } from "../../utils/utils";

const { Option } = Select;

interface PartDropdownTagTypesProps {
    name: string;
    label: string;
    options: any;
    values?: any;
    setValues?: any;
    disabled: boolean;
}

const PartDropdownTagTypes = ({
    options,
    label,
    values,
    setValues,
    disabled
}: PartDropdownTagTypesProps) => {
    const ALL_SELECTED = "0";
    const [allSelect, setAllSelect] = useState(false);

    function tagRender(props: any) {
        if (!values.includes(0) && values.length !== options.length - 1) {
            return (
                <span>
                    {options.find((el: any) => el.value == props.value)?.label}
                </span>
            );
        } else if (values.length === options.length - 1) {
            setAllSelect(true);
            return <span className="all-tag-selected">All Tags</span>;
        } else {
            setAllSelect(true);
            return <span className="all-tag-selected">All Tags</span>;
        }
    }

    const allSelected = values && values.length === options.length;

    return (
        <div
            className={
                allSelect
                    ? "form-element dropdown tag-type-wrapper all-selected"
                    : disabled
                    ? "form-element dropdown tag-type-wrapper locked"
                    : "form-element dropdown tag-type-wrapper"
            }
        >
            {label && <label>{label}</label>}
            <Select
                mode="multiple"
                getPopupContainer={getSelectPopupContainer}
                placeholder="Select Tag Type"
                value={values}
                disabled={disabled}
                onSelect={(value: any) => {
                    if (value === 0) {
                        setValues([value]);
                        setAllSelect(true);
                    } else {
                        setValues([
                            ...values.filter(
                                (selectedValue: any) => selectedValue !== 0
                            ),
                            value
                        ]);
                        setAllSelect(false);
                    }
                }}
                onDeselect={(value: any) => {
                    if (value === 0) {
                        // If Select All is deselected, deselect all other options
                        setValues([]);
                    } else {
                        // If any other option is deselected, deselect Select All
                        setValues(
                            values.filter(
                                (selectedValue: any) =>
                                    selectedValue !== value &&
                                    selectedValue !== 0
                            )
                        );
                    }
                }}
                showArrow={true}
                showSearch={false}
                suffixIcon={
                    <div className="select-icon">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                }
                tagRender={tagRender}
                dropdownRender={(menu) => (
                    <div className={"brokers-dropdown tag-type"}>
                        <Option key={ALL_SELECTED}>
                            <Checkbox checked={allSelected} value={0}>
                                All Tag Types
                            </Checkbox>
                        </Option>
                        {menu}
                    </div>
                )}
            >
                {options.map((option: any) => (
                    <Option
                        key={option.value}
                        value={option.value}
                        classname={"test"}
                    >
                        <Checkbox
                            checked={values.includes(option.value)}
                            value={option.value}
                        >
                            {option.label}
                        </Checkbox>
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default PartDropdownTagTypes;
