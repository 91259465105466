import Collection from "../valueObject/Collection";
import FavoritePropertyEntity from "./FavoritePropertyEntity";

const NO_INFO = "-";

export default class FavoritePropertyViewMapper {
    static map(data: Collection) {
        // if (data.isEmpty()) return [];
        return data.map((item: FavoritePropertyEntity, index: number) => {
            return {
                key: index,
                address: item.hasAddress() ? item.address.value : NO_INFO,
                city: item.hasCity() ? item.city.value : NO_INFO,
                state: item.hasState() ? item.state.value : NO_INFO,
                propertyType: item.hasPropertyType()
                    ? item.propertyType.name
                    : NO_INFO,
                favoriteType: item.hasStarType() ? item.starType.name : "Other",
                containNotes: item.hasContainNotes()
                    ? item.containNotes.value
                    : NO_INFO,
                propertyId: item.hasPropertyId()
                    ? item.propertyId.value
                    : NO_INFO,
                hasCalculation: item.hasHasCalculation()
                    ? item.hasCalculation.value
                    : false,
                hasDocuments: item.hasHasDocuments()
                    ? item.hasDocuments.value
                    : false,
                hasPortfolio: item.hasHasPortfolio()
                    ? item.hasPortfolio.value
                    : false,
                createdAt: item.hasCreatedAt() ? item.createdAt : NO_INFO,
                hasAlert: item.hasHasAlerts() ? item.hasAlert.value : false,
                latitude: item.hasLatitude() ? item.latitude.value : NO_INFO,
                longitude: item.hasLongitude() ? item.longitude.value : NO_INFO
            };
        }).items;
    }

    static mapExtended(data: FavoritePropertyEntity) {
        const mappedData: any = {};

        (mappedData.address = data.hasAddress() ? data.address.value : NO_INFO),
            (mappedData.city = data.hasCity() ? data.city.value : NO_INFO),
            (mappedData.state = data.hasState() ? data.state.value : NO_INFO),
            (mappedData.propertyType = data.hasPropertyType()
                ? data.propertyType.name
                : NO_INFO),
            (mappedData.favoriteType = data.hasStarType()
                ? data.starType.name
                : "Other"),
            (mappedData.containNotes = data.hasContainNotes()
                ? data.containNotes.value
                : NO_INFO),
            (mappedData.propertyId = data.hasPropertyId()
                ? data.propertyId.value
                : NO_INFO),
            (mappedData.hasCalculation = data.hasHasCalculation()
                ? data.hasCalculation.value
                : false),
            (mappedData.hasDocuments = data.hasHasDocuments()
                ? data.hasDocuments.value
                : false),
            (mappedData.hasPortfolio = data.hasHasPortfolio()
                ? data.hasPortfolio.value
                : false),
            (mappedData.createdAt = data.hasCreatedAt()
                ? data.createdAt
                : NO_INFO),
            (mappedData.hasAlert = data.hasHasAlerts()
                ? data.hasAlert.value
                : false),
            (mappedData.latitude = data.hasLatitude()
                ? data.latitude.value
                : NO_INFO),
            (mappedData.longitude = data.hasLongitude()
                ? data.longitude.value
                : NO_INFO),
            (mappedData.starReasons = data.hasStarReasons()
                ? data.starReasons._data
                : NO_INFO),
            (mappedData.starType = data.hasStarType()
                ? data.starType.name
                : NO_INFO);

        return mappedData;
    }
}
