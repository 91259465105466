import {
    faCalendarRange,
    faHouseBuilding,
    faLocationDot
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { xirr, convertRate } from "node-irr";
import PartInputNumber from "../../parts/PartInputNumber";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { findALenderSelectionSchema } from "../../../utils/formValidationSchemas";
import { useEffect, useState } from "react";
import BlockLedgerTable from "../BlockLedgerTable";
import PartDropdown from "../../parts/PartDropdown";
import PartRadioButton from "../../parts/PartRadioButton";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setShowLpInvestorPopup } from "../../../store";
import { useSearchParams } from "react-router-dom";
import LPInvestmentService from "../../../domain/lpInvestment/LPInvestmentService";
import TypeString from "../../../domain/valueObject/TypeString";
import BlockAddTransactionPopup from "../BlockAddTransactionPopup";
import { DatePicker, Space, Switch } from "antd";
import LPInvestmentViewMapper, {
    LPInvestmentData,
    LPInvestmentTransactions
} from "../../../domain/lpInvestment/LPInvestmentViewMapper";
import Collection from "../../../domain/valueObject/Collection";
import { parseToNumber } from "../../../utils/uiUitls";
import _ from "lodash";
import moment from "moment";

const distributionScheduleOptions = [
    {
        label: "Monthly",
        value: "MONTHLY"
    },
    {
        label: "Quarterly",
        value: "QUARTERLY"
    },
    {
        label: "Bi-Annually",
        value: "BI_ANNUAL"
    },
    {
        label: "Annualy",
        value: "ANNUAL"
    }
];

const BlockInvestmentTrackerSlidout = () => {
    const dispatch = useDispatch();
    const [transactions, setTransactions] = useState<LPInvestmentData | null>(
        null
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [transactionPopupOpen, setTransactionPopupOpen] = useState<any>(null);
    const [propertyAddress, setPropertyAddress] = useState<any>("");
    const [totalInvested, setTotalInvestment] = useState<any>(0);
    const [totalReceived, setTotalReceived] = useState<any>(0);
    // const [monthsDiff, setMonthsDiff] = useState<any>(0);
    const [irrPercentage, setIrrPercentage] = useState<any>("0%");
    const [averageCashPercentage, setAverageCashPercentage] =
        useState<any>("0");
    const [trackDistributionValues, setTrackDistributionValues] =
        useState<any>(undefined);

    const sliceState = useSelector(indexSliceState);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [nextDistributionDate, setNextDistributionDate] = useState<any>(
        moment()
    );

    const {
        control,
        formState: { errors },
        setValue
    } = useForm({
        mode: "onChange",
        shouldFocusError: false,
        resolver: yupResolver(findALenderSelectionSchema)
    });

    useEffect(() => {
        setTrackDistributionValues(undefined);
        setNextDistributionDate(moment());
        getTransactions();
    }, [sliceState.showLpInvestmentPopup.propertyId]);

    useEffect(() => {
        if (sliceState.showLpInvestmentPopup.propertyAddress) {
            setPropertyAddress(
                sliceState.showLpInvestmentPopup.propertyAddress
            );
        } else if (sliceState.propertyCardItem.data) {
            setPropertyAddress(sliceState.propertyCardItem.data.address);
        }
    }, [
        sliceState.propertyCardItem.data,
        sliceState.showLpInvestmentPopup.propertyId
    ]);
    useEffect(() => {
        if (trackDistributionValues) handleSetTrackDistributions();
    }, [trackDistributionValues]);

    useEffect(() => {
        if (transactions?.transactions !== undefined) {
            calculateInvestmentDetails();
            setValue(
                "distributionTrackAlert",
                transactions.distributionTrackAlert
            );
            setValue(
                "distributionScheduleType",
                transactions.distributionScheduleType
            );
            const d = new Date(transactions.nextDistributionDate);
            const date = moment(d).format("MM/DD/YYYY");
            if (transactions.nextDistributionDate) {
                setNextDistributionDate(moment(date));
            }
        }
    }, [transactions?.transactions]);

    useEffect(() => {
        if (transactions?.transactions !== undefined) {
            calculateXirr(transactions.transactions);
        }
    }, [
        transactions?.transactions,
        transactions?.finalDistributionEstimate,
        trackDistributionValues
    ]);

    const handleSetTrackDistributions = async () => {
        await LPInvestmentService.updatePropertyPortfolio(
            new Collection({
                propertyID: sliceState.showLpInvestmentPopup.propertyId,
                ...trackDistributionValues
            })
        );
    };

    const calculateInvestmentDetails = () => {
        const investmentSum = transactions?.transactions
            .filter(
                (transaction: any) =>
                    transaction.transactionType === "INVESTMENT"
            )
            .reduce(
                (sum: any, transaction: any) => sum + transaction.amount,
                0
            );

        setTotalInvestment(investmentSum);

        const distributionSum = transactions?.transactions
            .filter(
                (transaction: any) =>
                    transaction.transactionType === "DISTRIBUTION"
            )
            .reduce(
                (sum: any, transaction: any) => sum + transaction.amount,
                0
            );

        setTotalReceived(distributionSum);

        const transactionsWithDate =
            transactions?.transactions.filter((t: any) => t.date) || [];

        let monthsDiff;

        if (transactionsWithDate.length > 0) {
            transactionsWithDate?.sort((a: any, b: any) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            });

            const firstTransactionDate = new Date(transactionsWithDate[0].date);
            const lastTransactionDate = new Date(
                transactionsWithDate[transactionsWithDate.length - 1].date
            );

            monthsDiff =
                (lastTransactionDate.getFullYear() -
                    firstTransactionDate.getFullYear()) *
                    12 +
                (lastTransactionDate.getMonth() -
                    firstTransactionDate.getMonth());
        } else {
            console.log("No valid dates");
        }

        distributionSum !== 0 && investmentSum !== 0
            ? setAverageCashPercentage(
                  (
                      (distributionSum /
                          investmentSum /
                          (monthsDiff ? monthsDiff : 1)) *
                      12 *
                      100
                  ).toFixed(1)
              )
            : setAverageCashPercentage("0");
    };

    const getTransactions = async () => {
        if (sliceState.showLpInvestmentPopup.propertyId) {
            try {
                //set skeleton to true
                setSkeletonLoader(true);
                const response = await LPInvestmentService.getAll(
                    new TypeString(sliceState.showLpInvestmentPopup.propertyId)
                );
                const mappedData = LPInvestmentViewMapper.map(response);
                setTransactions(mappedData);
                setTrackDistributionValues({
                    ...trackDistributionValues,
                    finalDistributionEstimateAmount:
                        mappedData.finalDistributionEstimate,
                    nextDistributionDate: moment(
                        mappedData.nextDistributionDate
                    ).format("YYYY-MM-DD"),
                    distributionScheduleType:
                        mappedData.distributionScheduleType,
                    distributionTargetAmount:
                        mappedData.distributionTargetAmount,
                    distributionTrackAlert: mappedData.distributionTrackAlert,
                    opportunitiesSubscription:
                        mappedData.opportunitiesSubscription
                });
            } catch (error) {
                console.log("Error getting transactions", error);
            } finally {
                // set skeleton to false
                setSkeletonLoader(false);
            }
        }
    };

    const handleAddTransactionClick = () => {
        setTransactionPopupOpen(true);
    };

    const handleDebounceOnChange = _.debounce((value, name) => {
        setTrackDistributionValues({
            ...trackDistributionValues,
            [name]: value
        });
    }, 1000);

    const prepareTransactionsForXirr = (
        transactions: LPInvestmentTransactions[]
    ) => {
        const investmentAmounts: any = [];
        const distributionAmounts: any = [];

        const finalDistributionEstimate = {
            amount: trackDistributionValues?.finalDistributionEstimateAmount
                ? trackDistributionValues?.finalDistributionEstimateAmount
                : 0,
            date: moment().format("YYYYMMDD")
        };

        transactions.forEach((item: any) => {
            if (item.transactionType === "INVESTMENT" && item.date) {
                investmentAmounts.push({
                    amount: -item.amount,
                    date: moment(item.date).format("YYYYMMDD")
                });
            } else if (item.transactionType === "DISTRIBUTION" && item.date) {
                distributionAmounts.push({
                    amount: item.amount,
                    date: moment(item.date).format("YYYYMMDD")
                });
            }
        });

        return [
            ...investmentAmounts,
            ...distributionAmounts,
            finalDistributionEstimate
        ];
    };

    const calculateXirr = (transactions: LPInvestmentTransactions[]) => {
        const xirrData = prepareTransactionsForXirr(transactions);
        const xirrResult = xirr(xirrData);
        const annualConvertedRate = convertRate(xirrResult.rate, 365);

        const xirrPercentage = annualConvertedRate.toLocaleString("en", {
            style: "percent",
            minimumFractionDigits: 1
        });

        setIrrPercentage(xirrPercentage);
    };

    return (
        <div className="investment-tracker">
            <div className="investment-tracker__header">
                <div className="investment-tracker__header-title-wrapper">
                    <span className="investment-tracker__header-title-logo">
                        LP
                    </span>
                    <span className="investment-tracker__header-title-text">
                        LP Investment Tracker
                    </span>
                </div>
                <div
                    className="investment-tracker__header-close"
                    onClick={() => {
                        dispatch(
                            setShowLpInvestorPopup({
                                isOpen: false,
                                propertyId: undefined,
                                propertyAddress: ""
                            })
                        );
                        if (searchParams?.has("lpShow")) {
                            searchParams.delete("lpShow");
                            setSearchParams(searchParams);
                        }
                    }}
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8.00016 6.586L12.7072 1.87897L14.1212 3.29297L9.41416 8.00001L14.1212 12.707L12.7072 14.121L8.00016 9.41401L3.29316 14.121L1.87916 12.707L6.58616 8.00001L1.87916 3.29297L3.29316 1.87897L8.00016 6.586Z"
                            fill="#03363D"
                        />
                    </svg>
                </div>
            </div>
            <div className="investment-tracker__body">
                <div className="investment-tracker__property-info-wrapper">
                    <span className="investment-tracker__property-address">
                        <FontAwesomeIcon icon={faLocationDot} />
                        {propertyAddress}
                    </span>

                    <span className="investment-tracker__property-type">
                        <FontAwesomeIcon icon={faHouseBuilding} />
                        {sliceState.showLpInvestmentPopup.propertyTypeName}
                    </span>
                </div>

                <div className="investment-tracker__investment-details">
                    <div className="investment-tracker__investment-details-left">
                        <h2 className="investment-tracker__investment-details-title">
                            Investment Details
                        </h2>
                        <div className="investment-tracker__investment-details-nums-wrapper">
                            <span className="investment-tracker__investment-details-nums-upper">
                                $ {totalInvested.toLocaleString()}
                            </span>
                            <span className="investment-tracker__investment-details-nums-lower">
                                Total Invested
                            </span>
                        </div>
                        <div className="investment-tracker__investment-details-nums-wrapper">
                            <span className="investment-tracker__investment-details-nums-upper">
                                $ {totalReceived.toLocaleString()}
                            </span>
                            <span className="investment-tracker__investment-details-nums-lower">
                                Total Received
                            </span>
                        </div>
                        <div className="investment-tracker__investment-details-nums-wrapper">
                            <span className="investment-tracker__investment-details-nums-upper">
                                {averageCashPercentage}%
                            </span>
                            <span className="investment-tracker__investment-details-nums-lower">
                                Average Cash on Cash
                            </span>
                        </div>
                    </div>
                    <div className="investment-tracker__investment-details-right">
                        <div className="investment-tracker__investment-details-input-wrapper">
                            <span className="investment-tracker__investment-details-input-title">
                                Final Distribution Estimate
                            </span>
                            <span className="investment-tracker__investment-details-input-subtitle">
                                Based on today&apos;s date
                            </span>
                            <div className="investment-tracker__investment-details-input-holder">
                                <PartInputNumber
                                    name="finalDistributionEstimateAmount"
                                    errors={errors}
                                    onBlur={(value: any) => {}}
                                    value={
                                        trackDistributionValues
                                            ? trackDistributionValues.finalDistributionEstimateAmount
                                            : transactions?.finalDistributionEstimate
                                    }
                                    onChange={(value: any) =>
                                        handleDebounceOnChange(
                                            parseToNumber(value),
                                            "finalDistributionEstimateAmount"
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="investment-tracker__investment-details-percentage-wrapper">
                            <span className="investment-tracker__investment-details-percentage-title">
                                My IRR for this Deal
                            </span>
                            {trackDistributionValues?.finalDistributionEstimateAmount &&
                            trackDistributionValues?.finalDistributionEstimateAmount !==
                                "0" ? (
                                <span className="investment-tracker__investment-details-percentage-value">
                                    {irrPercentage}
                                </span>
                            ) : (
                                <span className="investment-tracker__investment-details-percentage-value no-value">
                                    Enter Estimated Final Distribution
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="investment-tracker__track-distribution">
                    <h2 className="investment-tracker__track-distribution-title">
                        Track Distributions
                    </h2>

                    <div className="investment-tracker__track-distribution-inputs-wrapper">
                        <div className="investment-tracker__track-distribution-inputs-holder">
                            <Space direction="vertical">
                                <label className="datepicker-label">
                                    Anticipated Next Distribution
                                </label>
                                <DatePicker
                                    className={"datepicker test"}
                                    format="MM/DD/YYYY"
                                    allowClear={false}
                                    onChange={(rawValue, value: any) => {
                                        setNextDistributionDate(moment(value));
                                        setTrackDistributionValues({
                                            ...trackDistributionValues,
                                            nextDistributionDate:
                                                moment(value).format(
                                                    "YYYY-MM-DD"
                                                )
                                        });
                                    }}
                                    value={nextDistributionDate}
                                    suffixIcon={
                                        <FontAwesomeIcon
                                            icon={faCalendarRange}
                                        />
                                    }
                                />
                            </Space>
                        </div>
                        <div className="investment-tracker__track-distribution-inputs-holder">
                            <PartDropdown
                                name="distributionScheduleType"
                                control={control}
                                errors={errors}
                                placeholder="Choose only one option"
                                label="Distribution Schedule"
                                defaultValue={"MONTHLY"}
                                disabled={false}
                                onChange={(value: any) => {
                                    setTrackDistributionValues({
                                        ...trackDistributionValues,
                                        distributionScheduleType: value
                                    });
                                }}
                                data={distributionScheduleOptions}
                            />
                        </div>

                        <div className="investment-tracker__track-distribution-inputs-holder target-amount">
                            <PartInputNumber
                                name="distributionTargetAmount"
                                label="Distribution Target Amount"
                                errors={errors}
                                onBlur={(value: any) => {}}
                                value={
                                    trackDistributionValues
                                        ? trackDistributionValues.distributionTargetAmount
                                        : transactions?.distributionTargetAmount
                                }
                                onChange={(value: any) => {
                                    handleDebounceOnChange(
                                        parseToNumber(value),
                                        "distributionTargetAmount"
                                    );
                                }}
                            />
                        </div>

                        <div className="investment-tracker__track-distribution-inputs-holder alerts">
                            <PartRadioButton
                                name="distributionTrackAlert"
                                control={control}
                                errors={errors}
                                label="Alerts"
                                labelTooltip="Receive an alert when distribution is not entered within 5 days of target date.  "
                                onChange={(e: any) => {
                                    setTrackDistributionValues({
                                        ...trackDistributionValues,
                                        distributionTrackAlert:
                                            e.target.value === 1 ? true : false
                                    });
                                }}
                                options={[
                                    {
                                        value: 1,
                                        label: "On"
                                    },
                                    {
                                        value: 0,
                                        label: "Off"
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="investment-tracker__ledger">
                    <h2 className="investment-tracker__track-distribution-title ledger-title">
                        Ledger{" "}
                        <button
                            onClick={handleAddTransactionClick}
                            className="investment-tracker__add-transaction"
                        >
                            Add Transaction
                        </button>
                    </h2>

                    <BlockLedgerTable
                        transactions={transactions?.transactions}
                        getTransactions={getTransactions}
                        skeletonLoader={skeletonLoader}
                        setSkeletonLoader={setSkeletonLoader}
                    />
                    {transactionPopupOpen && (
                        <BlockAddTransactionPopup
                            transactionPopupOpen={transactionPopupOpen}
                            setTransactionPopupOpen={setTransactionPopupOpen}
                            getTransactions={getTransactions}
                        />
                    )}

                    <button
                        onClick={handleAddTransactionClick}
                        className="investment-tracker__add-transaction investment-tracker__add-transaction--alt"
                    >
                        Add Transaction
                    </button>
                </div>
            </div>
            <div className="investment-tracker__toggle">
                <div className="investment-tracker__toggle-container">
                    <Switch
                        defaultChecked
                        checked={
                            trackDistributionValues?.opportunitiesSubscription !==
                            undefined
                                ? trackDistributionValues.opportunitiesSubscription
                                : transactions?.opportunitiesSubscription
                        }
                        onChange={(checked: any) => {
                            setTrackDistributionValues({
                                ...trackDistributionValues,
                                opportunitiesSubscription: checked
                            });
                        }}
                    />
                </div>
                <div className="investment-tracker__toggle-text-wrapper">
                    <span className="investment-tracker__toggle-title">
                        Send me LP investment opportunities
                    </span>
                    <span className="investment-tracker__toggle-subtitle">
                        Opt-in to receive the latest investment opportunities
                        from GPARENCY’s clients
                    </span>
                </div>
            </div>
        </div>
    );
};

export default BlockInvestmentTrackerSlidout;
