import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Upload } from "antd";
import BlockUploadDragger from "./BlockUploadDragger";
import BlockSingleUploadItem from "./BlockSingleUploadItem";
import PropertyDocumentService from "../../../domain/propertyDocument/PropertyDocumentService";
import TypeString from "../../../domain/valueObject/TypeString";
import indexSliceState, { setUploadedDocuments } from "../../../store";
import Collection from "../../../domain/valueObject/Collection";
import PropertyDocumentViewMapper from "../../../domain/propertyDocument/PropertyDocumentViewMapper";
import { LoadingOutlined } from "@ant-design/icons";
import { hotjar } from "react-hotjar";

const loaderIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
import { AxiosRequestConfig } from "axios";
import mapTriggersSliceState, {
    setPropertyCardMarkAsFavorite,
    setPropertyFavoriteActionTriggered
} from "../../../store/mapTriggers";
import PropertyService from "../../../domain/property/PropertyService";

interface BlockUploadDocumentsProps {
    propertyId: string | undefined;
}

const SUPPORTED_FILE_TYPES = [
    "jpeg",
    "png",
    "jpg",
    "png",
    "pdf",
    "docx",
    "pptx",
    "xls",
    "xlsx",
    "gsheet",
    "odf",
    "txt"
];

const BlockUploadDocuments = ({ propertyId }: BlockUploadDocumentsProps) => {
    const query = PropertyService.getSearchQuery();
    const sliceState = useSelector(indexSliceState);

    const [showDeleteBtn, setShowDeleteBtn] = useState<string | null>(null);
    const [filesInDeleteProcess, setFilesInDeleteProcess] = useState<any[]>([]);
    const [filesInUploadProcess, setFilesInUploadProcess] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [uploadMessage, setUploadMessage] = useState<any[]>([]);
    const [filePercentage, setFilePercentage] = useState<any>({
        uid: null,
        percent: null,
        extension: null
    });
    const dispatch = useDispatch();
    const [documentsLoading, setDocumentsLoading] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    // This is just for UI purpose
    const [isActive, setIsActive] = useState(false);

    const handleDragOver = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setIsActive(true);
        // scroll the elements scroll to top
        document.getElementsByClassName(
            "ant-tabs-content-holder"
        )[0].scrollTop = 0;
    };

    const handleDragLeave = () => {
        setIsActive(false);
    };

    const handleDrop = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setIsActive(false);
    };

    const isFileInvalidFormat = (file: any) => {
        const extension = file.name.split(".").pop();

        if (!SUPPORTED_FILE_TYPES.includes(extension)) {
            return true;
        }
        return false;
    };
    const isDuplicateFile = (file: any) => {
        return (
            sliceState.uploadedDocuments.filter(
                (el: any) =>
                    el?.name === file.name || el?.fileName === file.name
            ).length > 0
        );
    };

    const handleUploadValidations = (file: any) => {
        const maxSizeExceeded = file.size / (1024 * 1024) > 10;
        setErrorMessage(null);

        if (maxSizeExceeded) {
            setErrorMessage(
                "The file is too large and cannot be uploaded. Please reduce the size of the file and try again."
            );
            return false;
        }

        if (isFileInvalidFormat(file)) {
            setErrorMessage(
                "The file type is not supported. Please try uploading a different file."
            );
            return false;
        }

        if (isDuplicateFile(file)) {
            return "duplicate";
        }

        return true;
    };

    const reader = new FileReader();

    useEffect(() => {
        return () => {
            setFilePercentage({});
        };
    }, []);

    useEffect(() => {
        setIsFavorite(false);
    }, [propertyId]);

    const props = {
        name: "file",
        multiple: false,
        accept: ".jpg, .jpeg, .png, .pdf, .docx, .pptx, .csv, .xls, .xlsx, .gsheet, .odf, .txt",
        maxCount: 10,
        showUploadList: false,
        beforeUpload: async (file: any) => {
            const valid = handleUploadValidations(file);
            if (valid) {
                const convertedFile: any = await file.arrayBuffer();

                reader.readAsDataURL(file);
                const contentType = file.type;

                // const handleOnUpload: AxiosRequestConfig = {
                const handleOnUpload = (loaded: number, total: number) => {
                    const percentCompleted = Math.round((loaded * 100) / total);
                    hotjar.event(
                        `Property Documents/Photos - File Uploaded - ${propertyId}`
                    );
                    setFilePercentage({
                        uid: file.uid,
                        percent: percentCompleted
                    });
                    // }
                };

                if (valid === "duplicate") {
                    const myArray = [...sliceState.uploadedDocuments];
                    //To find the index of the object that we want to replace
                    const index = myArray.findIndex(
                        (item) => item.fileName === file.name
                    );
                    //To replace the object with the new object
                    myArray.splice(index, 1, file);
                    dispatch(setUploadedDocuments(myArray));
                } else {
                    dispatch(
                        setUploadedDocuments([
                            ...sliceState.uploadedDocuments,
                            file
                        ])
                    );
                }

                try {
                    setFilesInUploadProcess((oldValues: any) => [
                        ...oldValues,
                        file.uid
                    ]);

                    // This is a two step process upload: First send propertyId and file name,
                    // and wait for that endpoint to return a new endpoint for the final upload step
                    const { responseData } =
                        await PropertyDocumentService.uploadDocumentStep1(
                            new Collection({
                                propertyID: propertyId,
                                fileName: file.name
                            })
                        );
                    await PropertyDocumentService.uploadDocumentStep2(
                        responseData.url,
                        convertedFile,
                        contentType,
                        handleOnUpload
                    );

                    if (propertyId) {
                        const docs = await getAllDocuments(propertyId, false);
                        const newDoc = docs.find(
                            (doc: any) => file.name === doc.fileName
                        );
                        if (valid !== "duplicate") {
                            dispatch(
                                setUploadedDocuments([
                                    ...sliceState.uploadedDocuments,
                                    newDoc
                                ])
                            );
                        }
                    }

                    setFilesInUploadProcess((oldValues: any) =>
                        oldValues.filter((val: any) => val !== file.uid)
                    );

                    // Add property to favorites on document upload if already wasn't favorited
                    if (
                        !(
                            isFavorite ||
                            sliceState.propertyCardItem.data.isFavorite.value
                        )
                    ) {
                        setIsFavorite(true);
                        dispatch(setPropertyCardMarkAsFavorite(true));
                        dispatch(setPropertyFavoriteActionTriggered(true));
                        query.apply();
                    }
                } catch (error) {
                    console.log(
                        "There was an error while trying to upload the document",
                        error
                    );
                    setUploadMessage((oldValue) => [...oldValue, file.uid]);
                } finally {
                    setFilesInUploadProcess((oldValues: any) =>
                        oldValues.filter((val: any) => val !== file.uid)
                    );
                    dispatch(setPropertyFavoriteActionTriggered(false));
                }
            }
            return false;
        },
        onDrop: (e: any) => {
            const file = e.dataTransfer?.files[0];
            handleUploadValidations(file);
        }
    };

    const onDeleteFile = async (id: string) => {
        setUploadMessage((oldValues: any) =>
            oldValues.filter((el: any) => el !== id)
        );
        if (propertyId) {
            setFilesInDeleteProcess((oldValues: any) => [...oldValues, id]);
            try {
                await PropertyDocumentService.deleteDocument(
                    new TypeString(propertyId),
                    new TypeString(id)
                );

                const newArray = sliceState.uploadedDocuments.filter(
                    (el: any) => el.id !== id
                );
                dispatch(setUploadedDocuments(newArray));
                hotjar.event(
                    `Property Documents/Photos - File Deleted - ${propertyId}`
                );
            } catch (error) {
                console.log(
                    "There was an error while trying to delete the document",
                    error
                );
            } finally {
                setShowDeleteBtn(null);
                setFilesInDeleteProcess((oldValues: any) =>
                    oldValues.filter((val: any) => val !== id)
                );
            }
        }
    };

    useEffect(() => {
        if (propertyId) {
            getAllDocuments(propertyId);
        }
    }, [propertyId]);

    const getAllDocuments = async (
        propertyId: string,
        loader: boolean = true
    ) => {
        // Loader is needed on initial document tab load
        try {
            if (loader) setDocumentsLoading(true);
            const response = await PropertyDocumentService.getAll(
                new TypeString(propertyId)
            );
            const documents = PropertyDocumentViewMapper.map(response);
            if (loader) {
                dispatch(setUploadedDocuments(documents));
            } else {
                return documents;
            }
        } catch (error) {
            console.log(error, "error getting files");
        } finally {
            setDocumentsLoading(false);
        }
    };

    return (
        <div
            className={
                isActive ? "upload-documents dragging-over" : "upload-documents"
            }
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <h4>Upload Documents or Photos</h4>
            <BlockUploadDragger
                draggerOptions={props}
                files={sliceState.uploadedDocuments}
            />
            {errorMessage && (
                <p className="upload-documents__error">{errorMessage}</p>
            )}
            <h4 className="upload-documents__title">Documents and/or Photos</h4>
            <div
                className={`upload-documents__wrapper ${
                    documentsLoading ? "loading" : ""
                }`}
            >
                {documentsLoading ? (
                    <Spin indicator={loaderIcon} />
                ) : sliceState.uploadedDocuments.length > 0 ? (
                    sliceState.uploadedDocuments.map(
                        (file: any, index: any) => {
                            return (
                                <BlockSingleUploadItem
                                    file={file}
                                    filesInDeleteProcess={filesInDeleteProcess}
                                    filesInUploadProcess={filesInUploadProcess}
                                    uploadMessage={uploadMessage}
                                    key={index}
                                    filePercentage={filePercentage}
                                    deleteHandler={onDeleteFile}
                                    showDeleteBtn={showDeleteBtn}
                                    setShowDeleteBtn={setShowDeleteBtn}
                                />
                            );
                        }
                    )
                ) : (
                    <p className="upload-documents__no-doc-text">
                        There are no documents or photos to display.
                    </p>
                )}
            </div>
        </div>
    );
};

export default BlockUploadDocuments;
