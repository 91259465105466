import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import BlockCookiePopup from "./components/blocks/BlockCookiePopup";
import reportWebVitals from "./reportWebVitals";
import store from "./store/storeIndex";
import { Auth0Provider } from "@auth0/auth0-react";
import RAMPersistence from "./utils/RAMPersistence";
import { BrowserRouter,  Routes, Route } from "react-router-dom";

export const RAM_PERSISTENCE = new RAMPersistence();

const domain: any = process.env.REACT_APP_AUTH0_DOMAIN;
const cookieDomain: any = process.env.REACT_APP_COOKIE_DOMAIN;
const clientId: any = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: any = process.env.REACT_APP_AUTH0_AUDIENCE;
const scope: any = process.env.REACT_APP_AUTH0_SCOPE;
const issuer: any = process.env.REACT_APP_AUTH0_ISSUER;
// this snippet of code is for getting the utm params for marketing.
const urlParams = new URLSearchParams(window.location.search);
const utm_source = urlParams.get("utm_source") || "";
const utm_campaign = urlParams.get("utm_campaign") || "";
const utm_medium = urlParams.get("utm_medium") || "";
const utm_term = urlParams.get("utm_term") || "";
const utm_content = urlParams.get("utm_content") || "";
const trial_code = urlParams.get("trial_code") || "";
const gclid = urlParams.get("gclid") || "";
import ReactGA from "react-ga4";
import LoginRedirect from "./components/Auth0/LoginRedirect";

//Initialize GA4
ReactGA.initialize("G-K0J39D5634");
ReactGA.initialize("G-MBS03YSECE");
ReactDOM.render(
    <>
        {!navigator.cookieEnabled && <BlockCookiePopup />}
        {navigator.cookieEnabled && (
            <Auth0Provider
                domain={domain}
                cookieDomain={cookieDomain}
                clientId={clientId}
                redirectUri={window.location.href.replace(/authorize/g, "")}
                audience={audience}
                scope={scope}
                issuer={issuer}
                useRefreshTokens={true}
                utm_source={utm_source}
                utm_campaign={utm_campaign}
                utm_medium={utm_medium}
                utm_term={utm_term}
                utm_content={utm_content}
                gclid={gclid}
                trial_code={trial_code}
            >
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/*"
                            element={
                                <Provider store={store}>
                                    <App />
                                </Provider>
                            }
                        />
                        <Route path="authorize" element={<LoginRedirect />} />
                    </Routes>
                </BrowserRouter>
            </Auth0Provider>
        )}
    </>,
    document.getElementById("root")
);

const SendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname
    });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
