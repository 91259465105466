import { useAuth0 } from "@auth0/auth0-react";

export default function PartNotLoggedInMessage() {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className="property-card__login-wrapper">
            <h1 className="property-card__login-title">Free Signup or Login</h1>
            <p className="property-card__login-text">
                <button
                    className="property-card__login-text-btn"
                    onClick={() => {
                        loginWithRedirect({
                            redirectUri: window.location.href
                        });
                    }}
                >
                    Sign Up
                </button>{" "}
                or{" "}
                <button
                    className="property-card__login-text-btn"
                    onClick={() => {
                        loginWithRedirect({
                            redirectUri: window.location.href
                        });
                    }}
                >
                    Log In
                </button>{" "}
                to see property data and experience marketplace tools such as
                Find a Lender and Underwriting Calculator.
            </p>
        </div>
    );
}
