import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng
} from "react-google-places-autocomplete";
import PropertyService from "../../domain/property/PropertyService";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import TypeString from "../../domain/valueObject/TypeString";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setAutoCompleteResult,
    setMapViewPortCenterCoords,
    setPropertyCardClassName,
    setPropertyCardItem,
    setSearchMapFromCalc
} from "../../store";
import { setTriggerZoomToPolygon } from "../../store/mapTriggers";
import { useSearchParams } from "react-router-dom";
import { hotjar } from "react-hotjar";
import TypeFloat from "../../domain/valueObject/TypeFloat";
import responsiveSliceState, {
    setCurrentView,
    setOverlays,
    setShowMapBtn
} from "../../store/responsive";
import IFrameMessageFactory from "../../domain/iFrameMessage/IFrameMessageFactory";
import SearchMapMessage from "../../domain/iFrameMessage/findLender/SearchMapMessage";

const BlockAutoCompleteSearch = ({ setSearchIsTriggered }: any) => {
    const sliceState = useSelector(indexSliceState);
    const responsiveSlice = useSelector(responsiveSliceState);

    const dispatch = useDispatch();
    const [value, setValue] = useState<any | undefined>(null);
    const [inputValue, setInputValue] = useState<any | undefined>(null);
    const [focus, setFocus] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const query: PropertySearchQuery = PropertyService.getSearchQuery();

    const inputRef = useRef<any>();

    useEffect(() => {
        initializeComponent();
    }, [JSON.stringify(query.autoCompleteSearchQuery)]);

    const initializeComponent = () => {
        if (
            query instanceof PropertySearchQuery &&
            query.hasAutoCompleteSearchQuery()
        ) {
            query.assignApplyTrigger("fromAutoCompleteToInputHandler", () => {
                fromAutocompleteObjectToInput();
            });
        }
    };

    useEffect(() => {
        if (inputRef && responsiveSlice.overlays.propertySearch) {
            inputRef.current.focus();
            const propSearchDropdown = document.querySelector(
                ".mobile-property-search"
            );
            if (propSearchDropdown) propSearchDropdown.scrollTo({ top: 0 });
        }
    }, [responsiveSlice]);

    useEffect(() => {
        // recieve message from iframe
        window.addEventListener("message", (event) => {
            const message = IFrameMessageFactory.reconstitute(event.data);
            if (message instanceof SearchMapMessage) {
                message!.recieve(() => {
                    inputRef.current.focus();
                    setFocus(true);
                    dispatch(
                        setOverlays({
                            hamburgerMenu: false,
                            propertySearch: responsiveSlice.overlays
                                .propertySearch
                                ? false
                                : true,
                            userMenu: false,
                            lenderDefault: false,
                            lenderOverlay: false,
                            indexesOverlay: false,
                            ratesOverlay: false,
                            myPropertiesOverlay: false
                        })
                    );
                    dispatch(
                        setShowMapBtn(
                            responsiveSlice.overlays.propertySearch
                                ? true
                                : false
                        )
                    );
                    hotjar.event(
                        `Mobile Header Property Search ${
                            responsiveSlice.overlays.propertySearch
                                ? "Closed"
                                : "Opened"
                        }`
                    );
                });
            }
        });
    }, [sliceState.lenderIframe]);

    useEffect(() => {
        if (sliceState.searchMapFromCalc && inputRef) {
            inputRef.current.focus();
            dispatch(setSearchMapFromCalc(false));
        }
    }, [sliceState.searchMapFromCalc]);

    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);

    //     if (searchParams.has("query")) {
    //         query.setAutoCompleteSearchQuery(
    //             new TypeString(searchParams.get("query") || "")
    //         );
    //         if (query.hasAutoCompleteSearchQuery()) {
    //             setValue({ label: query.autoCompleteSearchQuery.value });
    //         }
    //     }
    // }, []);

    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     if (currentParams && currentParams.query) {
    //         fromUrlToAutocompleteObject();
    //         fromAutocompleteObjectToInput();
    //     }
    //     fromAutocompleteObjectToQueryString();
    // }, [query.autoCompleteSearchQuery?.value, searchParams]);

    // const fromUrlToAutocompleteObject = () => {
    //     if (!(query instanceof SearchQuery)) return;

    //     if (searchParams.has("query")) {
    //         query.setAutoCompleteSearchQuery(
    //             new TypeString(searchParams.get("query") || "")
    //         );
    //     }
    // };
    const fromAutocompleteObjectToInput = () => {
        if (query.hasAutoCompleteSearchQuery()) {
            setValue({ label: query.autoCompleteSearchQuery.value });
        }
    };
    // const fromAutocompleteObjectToQueryString = () => {
    //     if (query.hasAutoCompleteSearchQuery()) {
    //         searchParams.set(
    //             "query",
    //             query.autoCompleteSearchQuery.value.toString()
    //         );
    //         // setSearchParams(searchParams);
    //     }
    // };

    // const getPropertyCardItem = async (id: number) => {
    //     const result = await PropertyService.getProperty(id);
    //     console.log(result);
    //     return result;
    // };

    // Extract google's API response address_components by specific keys
    const getCountryCodeComponent = (address_components: any, type: string) => {
        let value = "";
        address_components.forEach((comp: any) => {
            if (comp.types.includes(type)) {
                value = comp.short_name;
            }
        });
        return value;
    };

    const getUrlParameters = (result: any) => {
        const street =
            getCountryCodeComponent(result, "street_number") +
            " " +
            getCountryCodeComponent(result, "route");

        const state = getCountryCodeComponent(
            result,
            "administrative_area_level_1"
        );
        const county = getCountryCodeComponent(
            result,
            "administrative_area_level_2"
        );
        const zip = getCountryCodeComponent(result, "postal_code");
        const city = getCountryCodeComponent(result, "sublocality_level_1");

        return {
            street,
            state,
            county,
            zip,
            city
        };
    };

    const handleOnChange = (e: any) => {
        dispatch(setCurrentView("map"));

        dispatch(
            setPropertyCardItem({
                data: null,
                isVisible: false,
                lat: undefined,
                lng: undefined
            })
        );

        inputRef?.current?.blur();
        setValue(e);

        geocodeByPlaceId(e.value.place_id).then((results) => {
            dispatch(setAutoCompleteResult(results[0]));

            getLatLng(results[0]).then(({ lat, lng }) => {
                setSearchIsTriggered(true);

                if (
                    results[0].types.includes("street_address") ||
                    results[0].types.includes("premise") ||
                    results[0].types.includes("establishment")
                ) {
                    const params = getUrlParameters(
                        results[0].address_components
                    );

                    // If the user selects a street address, we want to show the map immediately. If the search is broad, like "New York",
                    // we want to show the map after the user clicks the search button.
                    dispatch(
                        setOverlays({
                            propertySearch: false
                        })
                    );

                    PropertyService.getPropertyByGeoData(
                        new TypeString(params.street),
                        new TypeString(params.state),
                        new TypeString(params.county),
                        new TypeString(params.zip),
                        new TypeString(
                            params.city.length
                                ? params.city
                                : getCountryCodeComponent(
                                      results[0].address_components,
                                      "locality"
                                  )
                        ),
                        new TypeFloat(lat),
                        new TypeFloat(lng)
                    )
                        .then((res: any) => {
                            dispatch(
                                setPropertyCardItem({
                                    data: res,
                                    isVisible: true,
                                    lat,
                                    lng
                                })
                            );
                            dispatch(setPropertyCardClassName(""));
                            setSearchIsTriggered(false);
                        })
                        .catch(() => {
                            dispatch(
                                setPropertyCardItem({
                                    data: null,
                                    isVisible: true,
                                    lat,
                                    lng
                                })
                            );
                            dispatch(
                                setPropertyCardClassName("right no-property")
                            );
                            setSearchIsTriggered(false);
                        });
                }

                if (
                    results[0].formatted_address ===
                    sliceState.autoCompleteResult?.formatted_address
                ) {
                    dispatch(setTriggerZoomToPolygon(results[0].geometry));
                }
                dispatch(
                    setMapViewPortCenterCoords({
                        lat,
                        lng
                    })
                );
                const hjEvent = `Input Autocomplete Address Search - ${
                    results[0].formatted_address ?? "Address not found"
                }`.replaceAll(",", "-");
                hotjar.event(hjEvent);
            });
        });
    };

    const onInput = (e: any) => {
        setInputValue(e);
    };

    const onFocusChange = () => {
        const inputEl: any = document.querySelector(
            ".site-header__container #react-select-address-input"
        );
        setInputValue(value?.label ?? "");
        setTimeout(() => {
            inputEl.setSelectionRange(0, value?.label?.length ?? 0);
            inputEl.focus();
        }, 0);
    };

    return (
        <div
            className={`autocomplete-search ${
                focus ? "autocomplete-search--focused" : ""
            } `}
            onFocus={() => {
                setFocus(true);
            }}
            onBlur={() => {
                setFocus(false);
            }}
        >
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ["us"]
                    }
                }}
                selectProps={{
                    styles: {
                        input: (provided: any) => ({
                            ...provided,
                            color: "black",
                            width: 350
                        }),
                        // text
                        option: (provided: any) => ({
                            ...provided,
                            color: "#03363D",
                            padding: "12px 36px"
                        }),
                        singleValue: (provided: any) => ({
                            ...provided,
                            color: "#03363d"
                        })
                    },
                    ref: inputRef,
                    instanceId: "address",
                    placeholder:
                        "Search by Address, City, State, Zip or County...",
                    value,
                    onChange: handleOnChange,
                    onInputChange: onInput,
                    onFocus: onFocusChange,
                    inputValue,
                    // onFocus: () => {
                    //     setValue(null);
                    // },
                    // defaultMenuIsOpen: true,
                    isClearable: true,
                    openMenuOnClick: false
                }}
                onLoadFailed={(error) => {
                    notification.error({
                        message: `Request has failed with error message: ${error.message}`
                    });
                }}
            />
        </div>
    );
};

export default BlockAutoCompleteSearch;
