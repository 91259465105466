import { useState } from "react";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import indexSliceState, { setIframe, setLenderFlyout } from "../../../../store";
import { WHERE_FROM_CTA } from "../../../../utils/constants";
import PartButton from "../../../parts/PartButton";
import {
    faArrowUpRightFromSquare,
    faChevronDown,
    faClose,
    faEnvelopeCircle,
    faHandWave,
    faPhoneCircle,
    faFlag
} from "@fortawesome/pro-regular-svg-icons";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {Tooltip} from "antd";

const BlockLenderFlyout = ({
    isMenuCollapsed
}: {
    isMenuCollapsed: boolean;
}) => {
    const dispatch = useDispatch();
    const sliceState = useSelector(indexSliceState);
    const { lenderFlyout } = useSelector(indexSliceState);
    const [isOtherContactsCollapsed, setIsOtherContactsCollapsed] =
        useState(true);
    return (
        <div
            className={`lender-flyout ${
                lenderFlyout.isOpen && !isMenuCollapsed ? "open" : ""
            } ${isMenuCollapsed ? "collapsed" : ""}`}
        >
            {lenderFlyout.isOpen ? (
                <div className="lender-flyout__inner">
                    <div className="lender-flyout__header">
                        <span className="lender-flyout__name">
                            {lenderFlyout.data?.name}
                        </span>

                        <span
                            className="lender-flyout__flag"
                            onClick={() => {
                                dispatch(
                                    setIframe({
                                        show: true,
                                        street: "",
                                        city: "",
                                        propId: "",
                                        mapLat: "",
                                        mapLng: "",
                                        mapZoom: "",
                                        type: "lenderCredits",
                                        authUser: "true",
                                        whereFrom:
                                            WHERE_FROM_CTA.LEFT_NAV_LENDER_FLYOUT_INFO_SHARE
                                    })
                                );
                            }}
                        >
                            <span className="lender-flyout__flag-icon">
                                <FontAwesomeIcon
                                    icon={faFlag}
                                    style={{
                                        width: "16px",
                                        height: "16px"
                                    }}
                                ></FontAwesomeIcon>
                                <div>
                                    <p>Report Lender Data Issue</p>
                                </div>
                            </span>
                        </span>

                        {/* {lenderFlyout.type === "originator-profiles" && (
                        <h5>ORIGINATOR PROFILES</h5>
                    )} */}
                        <span
                            onClick={() => {
                                const type =
                                    lenderFlyout.type === "lending-details"
                                        ? "Institution"
                                        : "Originators";
                                hotjar.event(
                                    `Find a Lender View ${type} Close ${lenderFlyout.data?.name}}`
                                );
                                dispatch(
                                    setLenderFlyout({
                                        isOpen: false,
                                        data: undefined,
                                        type: undefined
                                    })
                                );
                            }}
                            className="lender-flyout__close"
                        >
                            <FontAwesomeIcon icon={faClose} />
                        </span>
                    </div>
                    <div className={`lender-flyout__main`}>
                        <div className="lender-flyout__detail-wrap">
                            <div className="lender-flyout__detail">
                                <h6>Property Type</h6>
                                <p>
                                    {lenderFlyout.data.propertyTypes.length
                                        ? lenderFlyout.data.propertyTypes.map(
                                              (pType: any, index: number) => {
                                                  return index ===
                                                      lenderFlyout.data
                                                          .propertyTypes
                                                          .length -
                                                          1
                                                      ? pType.name
                                                      : pType.name + ", ";
                                              }
                                          )
                                        : "-"}
                                </p>
                            </div>
                            <div className="lender-flyout__detail">
                                <h6>Execution Type</h6>
                                <p>
                                    {lenderFlyout.data.executionTypes.length
                                        ? lenderFlyout.data.executionTypes.map(
                                              (eType: any, index: number) => {
                                                  return index ===
                                                      lenderFlyout.data
                                                          .executionTypes
                                                          .length -
                                                          1
                                                      ? eType.name
                                                      : eType.name + ", ";
                                              }
                                          )
                                        : "-"}
                                </p>
                            </div>
                        </div>
                        <div className="lender-flyout__detail">
                            <h6>States Originating</h6>
                            <p>
                                {lenderFlyout.data.states.length
                                    ? lenderFlyout.data.states.map(
                                          (state: any, index: number) => {
                                              return index ===
                                                  lenderFlyout.data.states
                                                      .length -
                                                      1
                                                  ? state.name
                                                  : state.name + ", ";
                                          }
                                      )
                                    : "-"}
                            </p>
                        </div>
                        <div className="lender-flyout__rcmd-origs">
                            {lenderFlyout.data.originators.length > 0 && (
                                <>
                                    <h6>
                                        {!lenderFlyout.data
                                            .isLenderNameSearch &&
                                            "Recommended "}
                                        Originators (
                                        {lenderFlyout.data.originators.length})
                                    </h6>
                                    {!lenderFlyout.data.isLenderNameSearch && (
                                        <p>
                                            Results have an individual profile
                                            that matches your search criteria.
                                            Originators with the most recently
                                            updated profiles are shown first.
                                        </p>
                                    )}
                                </>
                            )}
                            <div className="lender-flyout__rcmd-origs__wrap">
                                {lenderFlyout.data.originators.map(
                                    (originator: any) => (
                                        <div
                                            key={originator.id}
                                            className="lender-flyout__rcmd-orig"
                                        >
                                            <span className="lender-flyout__orig-name">
                                                {originator.name}{" "}
                                                {originator.primary && (<Tooltip placement="topLeft" title={"Primary Contact"}>
                                                        <FontAwesomeIcon
                                                            className="lender-flyout__orig-star"
                                                            icon={solid("star")}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </span>
                                            <div className="lender-flyout__orig-inner">
                                                {originator.email && (
                                                    <a
                                                        href={`mailto:${originator.email}`}
                                                        className="lender-flyout__orig-email"
                                                        onClick={() => {
                                                            hotjar.event(
                                                                `Find a Lender Recommended Originators Email Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faEnvelopeCircle
                                                            }
                                                        />
                                                        {originator.email}
                                                    </a>
                                                )}
                                                {originator.phone && (
                                                    <a
                                                        href={`tel:${originator.phone}`}
                                                        className="lender-flyout__orig-phone"
                                                        onClick={() => {
                                                            hotjar.event(
                                                                `Find a Lender Recommended Originators Phone Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPhoneCircle}
                                                        />
                                                        {originator.phone}
                                                    </a>
                                                )}
                                            </div>
                                            {originator.slug && (
                                                <PartButton
                                                    type="secondary"
                                                    onClick={() => {
                                                        hotjar.event(
                                                            `Find a Lender Recommended Originators View Profile Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                        );
                                                        window.open(
                                                            `https://find-a-lender.gparency.com/lender/${originator.slug}`
                                                        );
                                                    }}
                                                >
                                                    <>
                                                        {" "}
                                                        View Profile
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faArrowUpRightFromSquare
                                                            }
                                                        />
                                                    </>
                                                </PartButton>
                                            )}
                                        </div>
                                    )
                                )}
                                <div className="lender-flyout__rcmd-orig special-item">
                                    <div className="lender-flyout__rcmd-orig__inner">
                                        <FontAwesomeIcon icon={faHandWave} />
                                        <span className="lender-flyout__orig-name">
                                            Need Help Financing?
                                        </span>
                                    </div>
                                    <p>
                                        If you’re looking for debt, our expert
                                        banking team can help you finance your
                                        next deal.
                                        <PartButton
                                            type="secondary"
                                            onClick={async () => {
                                                hotjar.event(
                                                    `Find a Lender Let GPARENCY Help ${lenderFlyout.data.name}`
                                                );

                                                if (
                                                    sliceState.findALenderData
                                                        .lastPropertyCardClicked
                                                        ?.propertyId
                                                ) {
                                                    let splitAddress: any;
                                                    let streetLine: any;
                                                    let cityLine: any;
                                                    if (
                                                        sliceState
                                                            .findALenderData
                                                            .lastPropertyCardClicked
                                                            ?.address
                                                    ) {
                                                        splitAddress =
                                                            sliceState.findALenderData.lastPropertyCardClicked?.address.split(
                                                                ","
                                                            );
                                                    } else if (
                                                        sliceState
                                                            .autoCompleteResult
                                                            ?.formatted_address
                                                    ) {
                                                        splitAddress =
                                                            sliceState.autoCompleteResult?.formatted_address.split(
                                                                ","
                                                            );
                                                    }
                                                    if (
                                                        splitAddress.length ===
                                                        3
                                                    ) {
                                                        streetLine =
                                                            splitAddress[0];
                                                        cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
                                                    }

                                                    if (
                                                        splitAddress.length ===
                                                        4
                                                    ) {
                                                        streetLine = `${splitAddress[0]}`;
                                                        cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}`;
                                                    } else if (
                                                        splitAddress.length ===
                                                        5
                                                    ) {
                                                        streetLine = `${splitAddress[0]}`;
                                                        cityLine = `${splitAddress[1]}, ${splitAddress[2]}, ${splitAddress[3]}, ${splitAddress[4]}`;
                                                    } else {
                                                        streetLine =
                                                            splitAddress[0];
                                                        cityLine = `${splitAddress[1]}, ${splitAddress[2]}`;
                                                    }
                                                    dispatch(
                                                        setIframe({
                                                            show: true,
                                                            street:
                                                                streetLine ||
                                                                "",
                                                            city:
                                                                cityLine || "",
                                                            propId: sliceState
                                                                .findALenderData
                                                                ?.lastPropertyCardClicked
                                                                .propertyId,
                                                            mapLat: sliceState
                                                                .propertyCardItem
                                                                ?.lat,
                                                            mapLng: sliceState
                                                                .propertyCardItem
                                                                ?.lng,
                                                            mapZoom:
                                                                sliceState
                                                                    .iframe
                                                                    .zoom,
                                                            type: "finance",
                                                            authUser: "true",
                                                            whereFrom:
                                                                WHERE_FROM_CTA.LEFT_NAV_LENDER_FLYOUT
                                                        })
                                                    );
                                                } else {
                                                    dispatch(
                                                        setIframe({
                                                            type: "get-financing",
                                                            whereFrom:
                                                                WHERE_FROM_CTA.LEFT_NAV_LENDER_FLYOUT,
                                                            getFinancingType:
                                                                true
                                                        })
                                                    );
                                                }
                                            }}
                                        >
                                            Let GPARENCY Help
                                        </PartButton>
                                    </p>
                                </div>
                                {sliceState.isLenderUser && (
                                    <div className="lender-flyout__rcmd-lenders">
                                        Lenders{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://lender.gparency.com"
                                        >
                                            CLICK HERE
                                        </a>{" "}
                                        to update your lending profile and see
                                        quote requests.
                                    </div>
                                )}
                            </div>
                        </div>
                        {lenderFlyout.data.otherOriginators.length > 0 && (
                            <div
                                className={`lender-flyout__rcmd-origs other-contacts ${
                                    isOtherContactsCollapsed ? "collapsed" : ""
                                }`}
                            >
                                <h6
                                    onClick={() => {
                                        hotjar.event(
                                            `Find a Lender Other Lender Contacts ${
                                                isOtherContactsCollapsed
                                                    ? "Expand"
                                                    : "Collapse"
                                            } ${lenderFlyout.data.name}`
                                        );
                                        setIsOtherContactsCollapsed(
                                            !isOtherContactsCollapsed
                                        );
                                    }}
                                >
                                    View Other Lender Contacts (
                                    {lenderFlyout.data.otherOriginators.length}){" "}
                                    {lenderFlyout.data.otherOriginators
                                        .length !== 0 && (
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    )}
                                </h6>
                                <div className="lender-flyout__rcmd-origs__wrap">
                                    {lenderFlyout.data.otherOriginators.map(
                                        (originator: any) => (
                                            <div
                                                key={originator.id}
                                                className="lender-flyout__rcmd-orig"
                                            >
                                                <div>
                                                    <span className="lender-flyout__orig-name">
                                                        {originator.name}
                                                    </span>
                                                    <div className="lender-flyout__orig-wrap">
                                                        {originator.email && (
                                                            <a
                                                                href={`mailto:${originator.email}`}
                                                                className="lender-flyout__orig-email"
                                                                onClick={() => {
                                                                    hotjar.event(
                                                                        `Find a Lender Other Lender Contacts Email Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                                    );
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEnvelopeCircle
                                                                    }
                                                                />
                                                                {
                                                                    originator.email
                                                                }
                                                            </a>
                                                        )}
                                                        {originator.phone && (
                                                            <a
                                                                href="tel:512-515-1414"
                                                                className="lender-flyout__orig-phone"
                                                                onClick={() => {
                                                                    hotjar.event(
                                                                        `Find a Lender Other Lender Contacts Phone Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                                    );
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPhoneCircle
                                                                    }
                                                                />
                                                                {
                                                                    originator.phone
                                                                }
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                                {originator.slug && (
                                                    <PartButton
                                                        type="secondary"
                                                        onClick={() => {
                                                            hotjar.event(
                                                                `Find a Lender Other Lender Contacts View Profile Click, ${lenderFlyout.data.name} - ${originator.name}`
                                                            );
                                                            window.open(
                                                                `https://find-a-lender.gparency.com/lender/${originator.slug}`
                                                            );
                                                        }}
                                                    >
                                                        View Profile
                                                    </PartButton>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default BlockLenderFlyout;
