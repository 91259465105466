import PropertyType from "../property/valueObject/PropertyType";
import Collection from "../valueObject/Collection";
import TypeBoolean from "../valueObject/TypeBoolean";
import TypeFloat from "../valueObject/TypeFloat";
import TypeString from "../valueObject/TypeString";
import FavoriteType from "./valueObject/FavoriteType";

export default class FavoritePropertyEntity {
    _address: TypeString;
    _city: TypeString;
    _state: TypeString;
    _propertyType: PropertyType;
    _favoriteType: FavoriteType;
    _containNotes: TypeBoolean;
    _propertyId: TypeString;
    _starReasons: Collection = new Collection([]);
    _starType: FavoriteType;
    _hasCalculation: TypeBoolean;
    _hasDocuments: TypeBoolean;
    _hasPortfolio: TypeBoolean;
    _createdAt: Date;
    _hasAlert: TypeBoolean;
    _latitude: TypeFloat;
    _longitude: TypeFloat;

    get address(): TypeString {
        return this._address;
    }
    setAddress(value: TypeString) {
        this._address = value;
        return this;
    }
    hasAddress(): boolean {
        return this._address instanceof TypeString;
    }

    get city(): TypeString {
        return this._city;
    }
    setCity(value: TypeString) {
        this._city = value;
        return this;
    }
    hasCity(): boolean {
        return this._city instanceof TypeString;
    }

    get state(): TypeString {
        return this._state;
    }

    setState(value: TypeString) {
        this._state = value;
        return this;
    }
    hasState(): boolean {
        return this._state instanceof TypeString;
    }

    get latitude(): TypeFloat {
        return this._latitude;
    }
    setLatitude(value: TypeFloat) {
        this._latitude = value;
        return this;
    }
    hasLatitude(): boolean {
        return this._latitude instanceof TypeFloat;
    }

    get longitude(): TypeFloat {
        return this._longitude;
    }
    setLongitude(value: TypeFloat) {
        this._longitude = value;
        return this;
    }
    hasLongitude(): boolean {
        return this._longitude instanceof TypeFloat;
    }

    get propertyType(): PropertyType {
        return this._propertyType;
    }
    setPropertyType(value: PropertyType) {
        this._propertyType = value;
        return this;
    }
    hasPropertyType(): boolean {
        return this._propertyType instanceof PropertyType;
    }

    get favoriteType(): FavoriteType {
        return this._favoriteType;
    }
    setFavoriteType(value: FavoriteType) {
        this._favoriteType = value;
        return this;
    }
    hasFavoriteType(): boolean {
        return this._favoriteType instanceof FavoriteType;
    }

    get containNotes(): TypeBoolean {
        return this._containNotes;
    }
    setContainNotes(value: TypeBoolean) {
        this._containNotes = value;
        return this;
    }
    hasContainNotes(): boolean {
        return this._containNotes instanceof TypeBoolean;
    }

    get propertyId(): TypeString {
        return this._propertyId;
    }

    setPropertyId(value: TypeString) {
        this._propertyId = value;
        return this;
    }
    hasPropertyId(): boolean {
        return this._propertyId instanceof TypeString;
    }

    get starReasons() {
        return this._starReasons;
    }

    setStarReasons(value: Collection) {
        this._starReasons = value;
        return this;
    }

    hasStarReasons(): boolean {
        return this._starReasons instanceof Collection;
    }

    get starType(): FavoriteType {
        return this._starType;
    }

    setStarType(value: FavoriteType) {
        this._starType = value;
        return this;
    }
    hasStarType(): boolean {
        return this._starType instanceof FavoriteType;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    setCreatedAt(value: Date) {
        this._createdAt = value;
        return this;
    }

    hasCreatedAt(): boolean {
        return this._createdAt instanceof Date;
    }

    get hasCalculation(): TypeBoolean {
        return this._hasCalculation;
    }

    setHasCalculation(value: TypeBoolean) {
        this._hasCalculation = value;
        return this;
    }

    hasHasCalculation(): boolean {
        return this._hasCalculation instanceof TypeBoolean;
    }

    get hasDocuments(): TypeBoolean {
        return this._hasDocuments;
    }

    setHasDocuments(value: TypeBoolean) {
        this._hasDocuments = value;
        return this;
    }

    hasHasDocuments(): boolean {
        return this._hasDocuments instanceof TypeBoolean;
    }

    get hasAlert(): TypeBoolean {
        return this._hasAlert;
    }

    setHasAlerts(value: TypeBoolean) {
        this._hasAlert = value;
        return this;
    }

    hasHasAlerts(): boolean {
        return this._hasAlert instanceof TypeBoolean;
    }

    get hasPortfolio(): TypeBoolean {
        return this._hasPortfolio;
    }

    setHasPortfolio(value: TypeBoolean) {
        this._hasPortfolio = value;
        return this;
    }

    hasHasPortfolio(): boolean {
        return this._hasPortfolio instanceof TypeBoolean;
    }
}
