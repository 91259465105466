import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faHouseBuilding, faUserTag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setPropertyCardClassName,
    setPropertyCardItem
} from "../../store";
import { setTriggerDocTab, setTriggerNotesTab } from "../../store/mapTriggers";
import EntityType from "../../domain/map/valueObject/EntityType";
import PropertyMarkerEntity from "../../domain/map/propertyMarker/PropertyMarkerEntity";
import TypeString from "../../domain/valueObject/TypeString";
import lenderPortfolioSliceState from "../../store/lenderPortfolio";
import PropertySearchQuery from "../../domain/property/queries/PropertySearchQuery";
import { Popover } from "antd";

interface BlockPillProps {
    data: PropertyMarkerEntity;
    // eslint-disable-next-line no-unused-vars
    setDisableDoubleClickZoom: (value: boolean) => void;
    lat: number;
    lng: number;
    // eslint-disable-next-line no-unused-vars
    zoomToNeighborhoodView: (data: PropertyMarkerEntity) => void;
    query: PropertySearchQuery;
}

const BlockPill = ({
    data,
    setDisableDoubleClickZoom,
    zoomToNeighborhoodView,
    query
}: BlockPillProps) => {
    const dispatch = useDispatch();
    const indexState = useSelector(indexSliceState);
    const lenderPortfolioState = useSelector(lenderPortfolioSliceState);

    useEffect(() => {
        setPropertyCardPosition();
    }, [data, indexState.propertyCardItem]);

    const setPropertyCardPosition = () => {
        if (
            (indexState.propertyCardItem?.data?.id?.value ??
                indexState.propertyCardItem?.data?.id) === data.id.value
        ) {
            setTimeout(() => {
                const pill = document.getElementById(`${data.id.value}`);
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                const cardWidth = 380;
                const cardHeight = 545;
                const pillTopOffset = pill?.getBoundingClientRect().top || 0;
                const pillIsCloseToTop = pillTopOffset < cardHeight / 2 + 70;
                const pillIsCloseToBottom =
                    windowHeight - pillTopOffset < cardHeight / 2 + 30;
                const pillLeftOffset = pill?.getBoundingClientRect().left || 0;
                const horizontalPosition =
                    windowWidth > pillLeftOffset + cardWidth + 160
                        ? "right"
                        : "left";
                const verticalPosition =
                    !pillIsCloseToTop && !pillIsCloseToBottom
                        ? "center"
                        : pillIsCloseToTop
                        ? "bottom"
                        : "top";
                dispatch(
                    setPropertyCardClassName(
                        `${horizontalPosition} ${verticalPosition}`
                    )
                );
            }, 0);
        }
    };

    const getPillColorBasedOnLender = (lenderName: TypeString) => {
        if (lenderName.value === lenderPortfolioState.firstLenderValue)
            return "map-pill navy-circle";
        if (lenderName.value === lenderPortfolioState.secondLenderValue)
            return "map-pill red-circle";
        if (lenderName.value === lenderPortfolioState.thirdLenderValue)
            return "map-pill yellow-circle";
    };

    const getPillClassNameBasedOnType = (data: PropertyMarkerEntity) => {
        if (!(data.entityType instanceof EntityType)) return;
        const mapPillClassName = "map-pill ";
        switch (data.entityType.value) {
            case 1:
                return mapPillClassName + "large";
            case 2:
                return mapPillClassName + "green-circle";
            case 3:
                return mapPillClassName + "blue-circle";
            case 4:
                return mapPillClassName + "grey-circle";
            case 5:
                if (data.hasLenderName())
                    return getPillColorBasedOnLender(data.lenderName!);
        }
    };

    const formatPopoverAddress = (addressString: string) => {
        if (addressString && addressString.length > 0) {
            const commaIndex = addressString.indexOf(",");

            if (commaIndex !== -1) {
                const street = addressString.slice(0, commaIndex).trim();
                const cityStateZip = addressString.slice(commaIndex + 1).trim();

                return { street, cityStateZip };
            } else {
                const street = addressString.trim();
                return { street, cityStateZip: null };
            }
        } else {
            return { street: "", cityStateZip: "" };
        }
    };

    const { street, cityStateZip } = formatPopoverAddress(data.address?.value);

    const popoverContent = (
        <div className="popover-pill-address">
            {data.propertyType && (
                <div className="popover-pill-address__property-type">
                    <FontAwesomeIcon
                        className="popover-pill-address__property-type__icon"
                        icon={faHouseBuilding}
                    />
                    <span className="popover-pill-address__property-type__text"></span>
                    {data.propertyType.value}
                </div>
            )}
            <span className="popover-pill-address__title">Address</span>
            <p className="popover-pill-address-text">{street}</p>
            <p className="popover-pill-address-text">{cityStateZip}</p>
        </div>
    );

    if (data)
        return (
            <div
                id={data.id.value}
                className={`map-pill__wrapper ${
                    (indexState.propertyCardItem?.data?.id?.value ??
                        indexState.propertyCardItem?.data?.id) === data.id.value
                        ? "active"
                        : ""
                }`}
                onClick={() => {
                    dispatch(setPropertyCardClassName(""));
                    dispatch(setTriggerNotesTab(false));
                    dispatch(setTriggerDocTab(false));
                    // If marker is not favorite, just open the property card
                    if (!data.isFavorite.value) {
                        dispatch(
                            setPropertyCardItem({
                                data,
                                isVisible: true,
                                address: data?.address?.value,
                                property_type: data?.propertyType?.value
                            })
                        );
                    } else if (query.zoomLevel.value >= 13) {
                        dispatch(
                            setPropertyCardItem({
                                data,
                                isVisible: true,
                                address: data?.address?.value,
                                property_type: data?.propertyType?.value
                            })
                        );
                    } else {
                        zoomToNeighborhoodView(data);
                    }
                    hotjar.event(
                        `${
                            data.entityType?.value === 1
                                ? `Listing Marker Click - ${data.id.value}`
                                : data.entityType?.value === 2
                                ? `Sales Comp Marker Click - ${data.id.value}`
                                : data.entityType?.value === 3
                                ? `Finance Comp Marker Click - ${data.id.value}`
                                : `Public Property Marker Click - ${data.id.value}` // We need to change event name with correct information
                        }`
                    );
                }}
                onMouseEnter={() => setDisableDoubleClickZoom(true)}
                onMouseLeave={() => setDisableDoubleClickZoom(false)}
            >
                {cityStateZip && street ? (
                    <Popover
                        content={
                            <div className="popover-pill-wrapper">
                                {popoverContent}
                            </div>
                        }
                        overlayStyle={{
                            paddingBottom: "10px",
                            pointerEvents: "none",
                            minWidth: "177px"
                        }}
                    >
                        <div className={`${getPillClassNameBasedOnType(data)}`}>
                            <span className="map-pill__inner">
                                {data.entityType?.value === 1 && (
                                    <FontAwesomeIcon icon={faUserTag} />
                                )}
                            </span>
                        </div>
                    </Popover>
                ) : (
                    <div className={`${getPillClassNameBasedOnType(data)}`}>
                        <span className="map-pill__inner">
                            {data.entityType?.value === 1 && (
                                <FontAwesomeIcon icon={faUserTag} />
                            )}
                        </span>
                    </div>
                )}

                {data.isFavorite.value ? (
                    <div
                        className={`map-pill__favorite ${
                            data.entityType?.value === 1
                                ? "fa-large"
                                : data.entityType?.value === 2
                                ? "fa-green-circle"
                                : data.entityType?.value === 3
                                ? "fa-blue-circle"
                                : "fa-grey-circle"
                        }`}
                    >
                        <FontAwesomeIcon icon={solid("star")} />
                        <FontAwesomeIcon icon={solid("star")} />
                    </div>
                ) : null}
            </div>
        );
    return <></>;
};

export default BlockPill;
