import Collection from "../valueObject/Collection";
import TypeString from "../valueObject/TypeString";
import FavoriteAdditionalDataEntity from "./FavoriteAdditionalDataEntity";

export default class FavoriteAdditionalDataFactory {
    reconstitute(data: Collection) {
        const instance = new FavoriteAdditionalDataEntity();

        if (data.has("pipelineCapacity")) {
            instance.setPipelineCapacity(
                new Collection(data.get("pipelineCapacity"))
            );
        }

        return instance;
    }
}
