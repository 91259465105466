import Exception from "../../../utils/exceptions/Exception";
import BaseValueObject from "../../valueObject/BaseValueObject";

export const ENTITY_TYPE_BUBBLE = 0;
export const ENTITY_TYPE_LISTING = 1;
export const ENTITY_TYPE_SALES_COMP = 2;
export const ENTITY_TYPE_REFINANCE_COMP = 3;
export const ENTITY_TYPE_PUBLIC_PROP = 4;
export const ENTITY_TYPE_LENDER = 5;

export default class EntityType extends BaseValueObject {
    _value: any;
    constructor(value: number) {
        super(value);
        this._value = value;
        this._validateValue();
    }

    getAvailableEntityTypes() {
        return [
            ENTITY_TYPE_BUBBLE,
            ENTITY_TYPE_LISTING,
            ENTITY_TYPE_SALES_COMP,
            ENTITY_TYPE_REFINANCE_COMP,
            ENTITY_TYPE_PUBLIC_PROP,
            ENTITY_TYPE_LENDER
        ];
    }

    get value(): number {
        return parseInt(this._value);
    }

    isBubble(): boolean {
        return this._value === ENTITY_TYPE_BUBBLE;
    }

    isListing(): boolean {
        return this._value === ENTITY_TYPE_LISTING;
    }

    isSalesComp(): boolean {
        return this._value === ENTITY_TYPE_SALES_COMP;
    }

    isRefinanceComp(): boolean {
        return this._value === ENTITY_TYPE_REFINANCE_COMP;
    }

    isPublicProperty(): boolean {
        return this._value === ENTITY_TYPE_PUBLIC_PROP;
    }

    isLender(): boolean {
        return this._value === ENTITY_TYPE_LENDER;
    }

    _validateValue() {
        if (this.getAvailableEntityTypes().indexOf(this._value) < 0) {
            throw new Exception("Invalid Entity Type", 400);
        }
    }
}
