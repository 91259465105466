import Collection from "../../valueObject/Collection";
import TypeString from "../../valueObject/TypeString";
import IFrameMessage from "../IFrameMessage";

export default class PropertyAddressMessage extends IFrameMessage {
  public static ACTION_NAME = "property-addres";

  constructor() {
    super(PropertyAddressMessage.ACTION_NAME);
  }

  public send(iframe: HTMLIFrameElement, address: TypeString) {
    super._send(
      new Collection({
        address: address.value,
      }),
      iframe
    );
  }

  public recieve(callback: Function, data: Collection) {
    callback(data.get("address"));
  }
}
