import { Select } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { hotjar } from "react-hotjar";
import { getSelectPopupContainer } from "../../utils/utils";

interface DropdownItemProps {
    label: string;
    value: string | number | boolean;
}
interface DropdownProps {
    name: string;
    label?: string;
    control: any;
    errors: any;
    isRequired?: boolean;
    defaultValue?: string | number;
    placeholder?: string;
    data: Array<DropdownItemProps>;
    value?: string | number | boolean;
    mode?: "multiple" | "tags" | undefined;
    onChange?: any;
    onClickHandler?: Function;
    dropdownClassName?: string;
    disabled?: boolean;
    formState?: any;
    filters?: boolean;
    getPopupContainer?: any;
    hotjarEventName?: string;
    hasCommonOptions?: boolean;
    commonOptionsOrder?: Number[];
}

const { Option } = Select;

const PartDropdown = ({
    name,
    label,
    control,
    errors,
    isRequired,
    data = [],
    mode,
    defaultValue,
    placeholder,
    disabled,
    onChange,
    onClickHandler,
    formState,
    dropdownClassName,
    getPopupContainer,
    hotjarEventName,
    hasCommonOptions,
    commonOptionsOrder
}: DropdownProps) => {
    const getClassnameIfDirty = () => {
        if (!name) return "";
        return formState.dirtyFields[name] ? ` input-dirty` : "";
    };

    const commonPropTypes = data
        .filter((item) => commonOptionsOrder?.includes(Number(item.value)))
        .sort(
            (a, b) =>
                commonOptionsOrder!.indexOf(Number(a.value)) -
                commonOptionsOrder!.indexOf(Number(b.value))
        );

    return (
        <div
            className={`form-element dropdown ${name && name in errors} ${
                formState ? getClassnameIfDirty() : ""
            }`}
        >
            {label && (
                <label htmlFor={name}>
                    {label}
                    {isRequired && <span aria-hidden>{" *"}</span>}
                </label>
            )}

            <div>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({ field }) => {
                        return (
                            <Select
                                // open={true}
                                getPopupContainer={getSelectPopupContainer}
                                {...field}
                                id={name}
                                aria-label={label}
                                mode={mode}
                                onChange={(values) => {
                                    field.onChange(values);
                                    if (onChange) onChange(values);
                                }}
                                onDropdownVisibleChange={(isOpen: boolean) => {
                                    if (hotjarEventName)
                                        hotjar.event(
                                            `${hotjarEventName} - ${
                                                isOpen ? "Open" : "Closed"
                                            }`
                                        );
                                }}
                                onClick={() => {
                                    if (onClickHandler) onClickHandler();
                                }}
                                disabled={disabled}
                                defaultValue={defaultValue}
                                dropdownClassName={dropdownClassName}
                                placeholder={placeholder}
                                placement="bottomLeft"
                                // style={{ width: 200 }}
                                // getPopupContainer={getPopupContainer, getSelectPopupContainer}
                                suffixIcon={
                                    <div className="select-icon">
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                }
                            >
                                {hasCommonOptions &&
                                    commonPropTypes.map((item: any, index) => (
                                        <Option
                                            key={`c-${index}`}
                                            value={item.value}
                                        >
                                            <div
                                                // FE should add class for common option
                                                className={
                                                    index ===
                                                    commonPropTypes.length - 1
                                                        ? "select-options select-options--common last"
                                                        : "select-options select-options--common"
                                                }
                                                onClick={() => {
                                                    if (hotjarEventName) {
                                                        hotjar.event(
                                                            `${hotjarEventName} - ${item.label} Selected`
                                                        );
                                                    }
                                                }}
                                            >
                                                {item.label}
                                            </div>
                                        </Option>
                                    ))}
                                {hasCommonOptions && (
                                    <Option
                                        disabled
                                        key={"divider"}
                                        className="select-options__divider"
                                    >
                                        <span></span>
                                    </Option>
                                )}
                                {data.map((item: any) => (
                                    <Option key={item.value} value={item.value}>
                                        <div
                                            className="select-options"
                                            onClick={() => {
                                                if (hotjarEventName) {
                                                    hotjar.event(
                                                        `${hotjarEventName} - ${item.label} Selected`
                                                    );
                                                }
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    </Option>
                                ))}
                            </Select>
                        );
                    }}
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }: any) => (
                        <p className="form-element__error-msg">{message}</p>
                    )}
                />
            </div>
        </div>
    );
};

export default PartDropdown;
