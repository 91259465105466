import React from "react";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, { setIframe } from "../../../store";
import { WHERE_FROM_CTA } from "../../../utils/constants";
import BlockMembershipCTA from "../BlockMembershipCTA";
import { PropertyCardData } from "../../../domain/property/PropertyViewMapper";

import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PartPropertyCardSingleItem from "../../parts/PartPropertyCardSingleItem";
import { capitalizeFirstLetter } from "../../../utils/utils";

interface BlockTabFinanceHistoryProps {
    propertyCardData: PropertyCardData;
}
const BlockTabFinanceHistory = ({
    propertyCardData
}: BlockTabFinanceHistoryProps) => {
    const indexSlice = useSelector(indexSliceState);
    const dispatch = useDispatch();

    return (
        <div
            className={`property-card__tab property-card__tab--finance ${
                propertyCardData.financeHistory === undefined ? "short-tab" : ""
            }`}
        >
            {propertyCardData.financeHistory !== undefined ? (
                <>
                    <div className="property-card__public-group">
                        <h4>
                            MORTGAGE{" "}
                            <div className="property-card__public-hover center">
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span>
                                    Data obtained through aggregation <br />
                                    of publicly available sources
                                </span>
                            </div>
                        </h4>
                        <PartPropertyCardSingleItem
                            name="Mortgage Date"
                            value={propertyCardData.financeHistory.mortgageDate}
                        />
                        <PartPropertyCardSingleItem
                            name="Loan Amount"
                            value={
                                propertyCardData.financeHistory.loanAmount
                                    ? String(
                                          propertyCardData.financeHistory
                                              .loanAmount
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Lender"
                            value={capitalizeFirstLetter(
                                propertyCardData.financeHistory.lender
                            )}
                        />
                        <PartPropertyCardSingleItem
                            name="Is Purchase"
                            value={propertyCardData.financeHistory.isPurchase}
                        />
                        <PartPropertyCardSingleItem
                            name="Maturity Date"
                            value={propertyCardData.financeHistory.maturityDate}
                        />
                        <PartPropertyCardSingleItem
                            name="Mortgage Paid Off"
                            value={
                                propertyCardData.financeHistory.mortgagePaidOff
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Blanket Mortgage"
                            value={
                                propertyCardData.financeHistory.blanketMortgage
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Number of Open Loans"
                            value={
                                propertyCardData.financeHistory
                                    .numberOfOpenLoans
                            }
                        />
                        <PartPropertyCardSingleItem
                            name="Open Loan Amount"
                            value={
                                propertyCardData.financeHistory.openLoanAmount
                                    ? String(
                                          propertyCardData.financeHistory
                                              .openLoanAmount
                                      ).split(".")[0]
                                    : undefined
                            }
                        />
                    </div>
                </>
            ) : (
                <span className="property-card__history-unavailable finance-history">
                    Public finance history data not available for this property.
                </span>
            )}
        </div>
    );
};

export default BlockTabFinanceHistory;
