import APIClient from "./utils/http/ApiClient";
export const PROPERTY_DATA_API = new APIClient(
    process.env.REACT_APP_BACKEND_API,
    true
);
export const PUBLIC_PROPERTY_DATA_API = new APIClient(
    process.env.REACT_APP_BACKEND_API
);
export const LOOKUP_API = new APIClient(process.env.REACT_APP_LOOKUP_MS);
export const DIRECTORY_API = new APIClient(
    process.env.REACT_APP_DIRECTORY_MS,
    true
);
export const FIND_LENDER_API = new APIClient(
    process.env.REACT_APP_LENDER_DATA_MS,
    true
);

export const USER_DATA_API = new APIClient(
    process.env.REACT_APP_USER_DATA_MS,
    true
);
