import Collection from "../../valueObject/Collection";
import IFrameMessage from "../IFrameMessage";

export default class HereIsATokenMessage extends IFrameMessage {
  public static ACTION_NAME = "here-is-a-token";

  constructor() {
    super(HereIsATokenMessage.ACTION_NAME);
  }

  public send(iframe: HTMLIFrameElement, token: string) {
    super._send(new Collection({ token: token }), iframe);
  }

  public recieve(callback: Function, data: Collection) {
    callback(data.get("token"));
  }
}
